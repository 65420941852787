<template>
  <div>
    <a id="resume-download-btn"
       href="/files/Adam%20Chaarawi%20Resume.pdf"
       target="_blank">
      Download Resume
    </a>

    <img id="resume-img"
         :src="resumeImgSrc"
         alt="Resume"/>
  </div>
</template>

<script>
  export default {
    name: 'Resume',

    // head: {
    //   title: 'Resume — Adam Chaarawi',
    //   meta: [
    //     {
    //       name: 'description',
    //       id: 'desc',
    //       content: "This is my acting resume.",
    //     }
    //   ],
    // },

    data() {
      return {
        // resumeImgSrc: 'https://res.cloudinary.com/dt7fbptp2/image/upload/v1705188007/personal/resume-screenshot_ourdkv.jpg',
        resumeImgSrc: require('@/assets/resume-img.jpg')
      }
    }
  }
</script>

<style scoped>
  #resume-download-btn {
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    padding: 1em 2em;
    border: solid 1px rgb(103, 103, 103);
    font-size: 0.85em;
    width: fit-content;
    margin: 0 auto 50px auto;
    color: rgb(103, 103, 103);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  #resume-download-btn:hover {
    opacity: 0.8;
  }

  #resume-img {
    width: 100%;
    max-width: min(750px, calc(100vw - 6em));
  }
</style>